<template>
<div class="app">
  <spinner v-if="!showContent || loading"/>

  <suspense>
    <template #default>
      <div class="app-body">
        <transition name="scale-fade" mode="out-in" >
          <div v-if="showContent" class="app-wrap">
            <div id="nav">
              <navigation v-if="!hideNav"/>
            </div>
            <router-view />

            <footer-note v-if="!hideNav"/>
          </div>
        </transition>

      </div>
    </template>

    <template #fallback>
      <spinner/>
    </template>
  </suspense>

  <modal :show="showNdpr" mode="small"> 
    <template v-slot:header> 
      <p class="modal-head">NDPR Policy</p>
    </template>
    
    <template v-slot:content>
      
      <p class="modal-text">We take your privacy seriously and only process your personal information to make your banking experience better. In accordance with NDPR, GDPR and other applicable regulation, continuing to use this platform indicates your consent to the processing of your personal data by Rockshield Microfinance Bank Ltd. It is as detailed in our Privacy Policy</p>
    </template>
  </modal>

  <div v-show="showImage" ref="image" class="lagos-loan">
    <img src="./assets/display/lagos-loan.webp" alt="image" class="lazyload">

    <fa-icon  @click="toggleShowImage" :icon="['fas', 'times']" class="loan-icon"/>
  </div>
   
  
</div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { auth } from "@/firebase/firebaseInit";
import { onAuthStateChanged } from 'firebase/auth';
import { mapActions, mapMutations } from 'vuex';

const Navigation = defineAsyncComponent(() => import('@/components/Navigation.vue'));
const FooterNote = defineAsyncComponent(() => import('@/components/FooterNote.vue'));


export default {
  components: {
    Navigation,
    FooterNote,
  },

  data() {
    return {
      showImage: null,
      showContent: false,
      hideNav: null,
      loading: null,
      showNdpr: null
    }
  },

  created () {
    this.checkRoute();
    this.showImage = true;

    onAuthStateChanged(auth, async (user) =>{
      this.loading = true;
      await this.updateUser(user);
      this.loading = false;
      if(user){
        
        await this.getCurrentUser(user);
        this.loading = false;
      }
    })
  },

  mounted() {
    
    this.checkRoute();
  },

  watch: {
    $route() {
      this.checkRoute();
    },
  },


  methods: {
    ...mapActions(['getCurrentUser']),
    ...mapMutations(['updateUser']),

    toggleShowImage() {
      this.showImage = !this.showImage;
      setTimeout(() => {
      this.showContent = true;
    }, 1000);
    },


    checkRoute() {
      if (
        this.$route.name === "admin-login" ||
        this.$route.name === "admin-register" ||
        this.$route.name === "account-list" ||
        this.$route.name === "customer-form" ||
        this.$route.name === "reset-password" ||
        this.$route.name === "kindly-login" ||
        this.$route.name === "complete-signIn" 
      ) {
        this.hideNav = true;
        this.showNdpr = false;
        this.showImage = false;
        this.showContent = true;
        return;
      }
      this.hideNav = false;
      this.showNdpr = true;
      //this.showImage = true;
      //this.showContent = false;
    },

    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

#app {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #033549;
}

.lagos-loan {
  @apply w-full relative;
  z-index: 99;
  height: 100vh;

  img {
    @apply object-contain h-full w-full;

  }

  .loan-icon {
    @apply cursor-pointer top-7 right-10 h-4 w-4 fixed bg-black text-white bg-opacity-50 p-2;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    z-index: 1001;
  }    
}

.modal-head {
  @apply text-base font-semibold tracking-wide;

  @screen md {
    @apply text-lg;
  }
}

.modal-text {
  @apply text-xs tracking-normal text-justify leading-normal;

  @screen md {
    @apply text-sm leading-6;
  }
}

.app {
 max-width: 100%;
 overflow-x: hidden;
}

.app-body {
  height: 100%;
}

.scale-fade-enter-active,
.scale-fade-leave-active {
  transition: transform 1s ease, opacity 1s ease;
}

.scale-fade-enter-from {
  /* transform: scale(0.8); */
  transform: translateY(20px);
  opacity: 0;
}

/* general styling */
button,
.btn{
  @apply  text-white text-sm text-center outline-none
  px-5 py-1 cursor-pointer border-none rounded-[30px] w-auto;

  @screen md {
    @apply py-2 px-6;
  }

  @screen lg {
    @apply text-base;
  }

  &:hover {
    @apply bg-opacity-80;
  }
}

// status button styling
.status-button {
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
  }
  font-size: 12px;
  margin-right: 30px;
  align-items: center;
  padding: 8px 30px;
  border-radius: 10px;
}

.red{
 @apply text-red-700
}

.blue {
  @apply bg-[#00ABF1];
}
.green {
  background-color: #33d69f;
}

.orange {
  background-color: #ff8f00;
}

.done {
  &::before {
    background-color: #33d69f;
  }
  color: #33d69f;
  background-color: rgba(51, 214, 160, 0.1);
}

.pending {
  &::before {
    background-color: #ff8f00;
  }
  color: #ff8f00;
  background-color: rgba(255, 145, 0, 0.1);
}

.init {
  &::before {
    background-color: #dfe3fa;
  }
  color: #dfe3fa;
  background-color: rgba(223, 227, 250, 0.1);
}

.box {
  width: 100%;
  padding: 40px 10px;
  max-width: 850px;
  margin: 0 auto;

  @media (min-width: 900px) {
    padding-top: 72px;
  }
}

</style>
