import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './assets/tailwind.css'
import { MotionPlugin } from '@vueuse/motion'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

// Firebse
import { auth } from "@/firebase/firebaseInit";
import { onAuthStateChanged } from 'firebase/auth';

// font-awesome icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, far, fab);

// ui components
const Modal = defineAsyncComponent(() => import('@/components/UI/BaseModal.vue'));
const Spinner = defineAsyncComponent(() => import('@/components/UI/Loading.vue'));
const NavButton = defineAsyncComponent(() => import('@/components/UI/BaseButton.vue'));


const app= createApp(App);

app.config.globalProperties.$defineAsyncComponent = defineAsyncComponent;

app.use(ElementPlus);
app.use(MotionPlugin)
app.use(store)
app.use(router)

app.component('fa-icon', FontAwesomeIcon)
app.component('modal', Modal)

app.component('spinner', Spinner)
app.component('nav-button', NavButton)

let isAppMounted = false;  // a flag to track if the app is already mounted

onAuthStateChanged(auth, () => {
  if (!isAppMounted) {
    app.mount('#app');
    isAppMounted = true;  // Set the flag to true after mounting
  }
});

