import { createStore } from 'vuex'
import { db } from "@/firebase/firebaseInit";
import { collection, doc, getDocs, getDoc, updateDoc} from "firebase/firestore";
import { uid } from 'uid';
import formValid from './formValid';
import CryptoJS from 'crypto-js';

export default createStore({

  modules: {
    formValid,
  },

  state: {

    //faq
    activeDropdownId: null,

    faqSections: [

      // Loan faq
      {
        name: 'Loan',
        
        faqDocs: [
          {
            id: uid(6),
            title: 'How much can I borrow?',
            graphs: [
              'Our minimum loan amount is N200,000 and a Maximum amount of N5,000,000. Both depend on borrower’s repayment capacity, based on verifiable business inventory and other criteria.',
            ]
          },
  
          {
            id: uid(6),
            title: 'What kind of loans do we offer?',
            graphs: [
              'We offer business loans for businesses that are already in existence/operation and salary advance loan.'
            ]
          },
  
          {
            id: uid(6),
            title: 'What documents do I require?',
            graphs: [
              'Passport Photograph, Valid means of identification, current bills, BVN (This are the basis requirement, more documents are needed for SME loan).'
            ]
          },
  
          {
            id: uid(6),
            title: 'What are other loan requirements?',
            graphs: [
              "• To access Rockshield’s business loan facility;",
  
              "• You must have a viable business.",
              "• You must have a physical office or shop with visible inventory.",
              "• The business location must be within our bank’s lending area.",
              "• It must have been in business for a period of at least one year."
            ]
          },
  
          {
            id: uid(6),
            title: 'How long does it take to get a loan?',
            graphs: [
              "It doesn’t take longer than it ought to as long as the necessary requirements are adequately met (less than 3 working days)."
            ]
          },

          {
            id: uid(6),
            title: 'What is the interest rate?',
            graphs: [
              "Interest rates are based on CBN prevailing rates as well as the loan amount. However, it is very favorable. "
            ]
          },

          {
            id: uid(6),
            title: 'Do I need an account to access a loan?',
            graphs: [
              "No fee or mandatory account opening is required before you can get a loan from us. However, opening an account with us is an asset in the long run."
            ]
          },

          {
            id: uid(6),
            title: 'Do I need a guarantor and collateral to access a loan',
            graphs: [
              "At least a guarantor is required. The need for collateral is dependent on the loan amount."
            ]
          }
        ],
      },

      // BAnking services faq
      {
        name: 'Banking Services',
        
        faqDocs: [
          {
            id: uid(6),
            title: 'What kind of account can I open?',
            graphs: [
              '• Savings',
              '• SCurrent (Company/Individual)',
              '• Fixed Deposit',
            ]
          },
  
          {
            id: uid(6),
            title: 'What is the requirement for account opening?',
            graphs: [
              'A valid ID-card, and a minimum opening balance of N1,000. You will also enjoy all charge-free mobile banking services (instant fund transfer, bill payment, utility bills, airtime top-up, balance enquiry).'
            ]
          },
  
          {
            id: uid(6),
            title: 'Can I make a deposit without coming to the bank?',
            graphs: [
              '• Correspondent bank from our partner banks',
              '• eBillsPay at any branch of all commercial banks in Nigeria',
              '• Internet banking transfer from all commercial banks in Nigeria.',
              '• Electronic transfer '
            ]
          },
  
          {
            id: uid(6),
            title: 'What is the interest rate on savings?',
            graphs: [
              "The interest rate is currently 5% to 15% per annum, depending on the saving product.",
            ]
          },
  
          {
            id: uid(6),
            title: 'What is the interest rate on terms deposit?',
            graphs: [
              "Interest rate as high as 15% per annum, the interest rate also depends on the amount and tenor."
            ]
          },
          {
            id: uid(6),
            title: 'What is the minimum amount fixable?',
            graphs: [
              "The minimum amount is N100,000."
            ]
          },
          {
            id: uid(6),
            title: 'If I need urgent funds, can I liquidate and get my funds?',
            graphs: [
              "Yes, however accrued interest may be charged depending on the type of interest payment chosen."
            ]
          },
          {
            id: uid(6),
            title: 'How can I get my interest?',
            graphs: [
              "Interest rate can be paid into your account monthly or upon maturity."
            ]
          }
        ],
      }
    ],

    // profile
    isAuthenticated: false,
    user: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileUserName: null,
    profileId: null,
    profileInitials: null,

    // form
    formData: [],
    formsLoaded: null,
    currentFormArray: [],
  },

  getters: {
    CURRENT_FORM_ARRAY(state){
      return state.currentFormArray;
    },

    FAQ_SECTIONS(state){
      return state.faqSections;
    },
    ACTIVE_DROPDOWN_ID(state) {
      return state.activeDropdownId;
    }
  },

  mutations: {

    setActiveDropdown(state, id) {
      state.activeDropdownId = id;
    },

    clearActiveDropdown(state) {
      state.activeDropdownId = null;
    },

    setAuthenticationStatus(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    
    updateUser(state, payload) {
      state.user = payload
    },

    setProfileInfo(state, payload){
      state.profileId = payload.id;
      state.profileEmail = payload.email;
      state.profileFirstName = payload.firstName;
      state.profileLastName = payload.lastName;
      
      state.profileUserName = payload.username;
    },

    setProfileInitials(state) {
      state.profileInitials =
        state.profileFirstName.match(/(\b\S)?/g).join("") + state.profileLastName.match(/(\b\S)?/g).join("");
    },

    setFormData(state, payload) {
      const updatedFormData = [...state.formData, payload]; 
      updatedFormData.sort((a, b) => new Date(b.formDate) - new Date(a.formDate)); 
    
      state.formData = updatedFormData; 
    },

    // setFormData(state, payload) {
    //   state.formData.push(payload);
    
    //   state.formData.sort((a, b) => new Date(b.formDateUnix) - new Date(a.formDateUnix));
    // },
    
    formsLoaded(state) {
      state.formsLoaded = true;
    },

    async setCurrentForm(state, payload) {
      state.currentFormArray = state.formData.filter(form => {
        return form.accountId === payload;
      });
    },

    updateStatusToDone(state, payload) {
      state.formData.forEach(form => {
        if (form.docId === payload){
          form.formDone = true;
          form.formPending =false;
        }
      })
    },

    updateStatusToPending(state, payload) {
      state.formData.forEach(form => {
        if (form.docId === payload){
          form.formDone = false;
          form.formPending = true;
          form.formInit = false;
        }
      })
    }
  },
  

  actions: {
    async getCurrentUser({commit}, user) {

      const usersCollection = collection(db, "users");

      const userDoc = doc(usersCollection, user.uid);
      const dbResults= await getDoc(userDoc);

      const passphrase = process.env.PASSPHRASE;

      const data = {
        lastName:  await this.dispatch('decryptData', { encryptedData: dbResults.data().lastName, passphrase}),
        firstName:  await this.dispatch('decryptData', { encryptedData: dbResults.data().firstName, passphrase}),
        email:  await this.dispatch('decryptData', { encryptedData: dbResults.data().email, passphrase}),
        username:  await this.dispatch('decryptData', { encryptedData: dbResults.data().username, passphrase}),
      }

      commit("setProfileInfo", data);
      commit("setProfileInitials");
    },

    async getForms({commit, state}){
      const getCollection = collection(db, 'account-forms');
      const results = await getDocs(getCollection);
      results.forEach(async doc => {
        if(!state.formData.some((accountInfo) => accountInfo.docId === doc.id)) {
          
          const passphrase = process.env.PASSPHRASE;

          const data = {
            docId: doc.id,
            formDateUnix: doc.data().formDateUnix,
            accountId: doc.data().accountId,

            formDate: await this.dispatch('decryptData', { encryptedData: doc.data().formDate, passphrase}),
            bvn: await this.dispatch('decryptData', { encryptedData: doc.data().bvn, passphrase}),
            nin: await this.dispatch('decryptData', { encryptedData: doc.data().nin, passphrase}),
            lastName:  await this.dispatch('decryptData', { encryptedData: doc.data().lastName, passphrase}),
            firstName:  await this.dispatch('decryptData', { encryptedData: doc.data().firstName, passphrase}),
          
            gender: await this.dispatch('decryptData', { encryptedData: doc.data().gender, passphrase}),
            email:  await this.dispatch('decryptData', { encryptedData: doc.data().email, passphrase}),
            
            resAddress:  await this.dispatch('decryptData', { encryptedData: doc.data().resAddress, passphrase}),
            resCountry:  await this.dispatch('decryptData', { encryptedData: doc.data().resCountry, passphrase}),

            // maritalStatus: await this.dispatch('decryptData', { encryptedData: doc.data().maritalStatus, passphrase}),
            
            chkTerms: doc.data().chkTerms,
            formPending: doc.data().formPending,
            formDone: doc.data().formDone,
            formInit: doc.data().formInit,
          };
          commit('setFormData', data);
        }
      });
      
      commit('formsLoaded')
    },

    async decryptData({ commit, state }, { encryptedData, passphrase }){
      return CryptoJS.AES.decrypt(encryptedData, passphrase).toString(CryptoJS.enc.Utf8);
    },

    async updateStatusToDoneSt({commit}, docId) {
      const getCollection = collection(db, 'account-forms');
      const getForm = doc(getCollection, docId);
      await updateDoc(getForm, {
        formDone: true,
        formPending: false,
      });
      commit('updateStatusToDone', docId);
    },

    async updateStatusToPendingSt({commit}, docId) {
      const getCollection = collection(db, 'account-forms');
      const getForm = doc(getCollection, docId);
      await updateDoc(getForm, {
        formDone: false,
        formPending: true,
        formInit: false,
      });
      commit('updateStatusToPending', docId);
    }

  },
})
