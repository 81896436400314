<template>
  <div class="home" id="home">
    <div class="home-wrap">
      <div class="welcome-section">
        <div class="left">
          <div class="message">
            <h1>Empowering Local Businesses</h1>
            <p>Easy, fast and convenient loans for growth and sustainability</p>
          </div>

          <div class="action">
            <div class="learn-more">
              <router-link class="lm-txt" :to="{name:'products-loan'}"
                >Learn more
                <fa-icon class="" :icon="['fas', 'arrow-right']" />
              </router-link>
            </div>
            <div class="create-account">
              <router-link :to="{ name: 'create-account' }">
                <button class="blue btn">Create Account</button>
              </router-link>
            </div>
          </div>

          <div class="license">
            <div class="licensed-by">
              <h3>DEPOSIT INSURED BY</h3>
              <img
                src="../assets/logo/ndic_small_logo-1 1.webp"
                alt="ndic logo"
              />
            </div>
            <div class="licensed-by">
              <h3>LICENSED BY</h3>
              <div class="cbn">
                <img src="../assets/logo/cbn.webp" alt="cbn" />
                <h3>Central Bank of Nigeria</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="images">
            <div class="first" v-motion-pop-visible>
              <img
                src="../assets/display/home images/holding-pastry.webp"
                alt="-baker-with-fresh-bread"
              />
            </div>
            <div class="second" v-motion-pop-visible>
              <img
                src="../assets/display/home images/flower-business.webp"
                alt=""
              />
            </div>
            <div class="third" v-motion-pop-visible>
              <img
                src="../assets/display/home images/woman-market.webp"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <!-- key figures -->
      <div class="key-figures">
        <h3>Key Figures</h3>
        <div class="figures-wrap">
          <div class="figures" v-motion-slide-visible-bottom>
            <h3>700+ million</h3>
            <p>Loan Disbursed</p>
          </div>
          <div class="figures" v-motion-slide-visible-bottom>
            <h3>13+ years</h3>
            <p>Experience</p>
          </div>
          <div class="figures" v-motion-slide-visible-bottom>
            <h3>500k+</h3>
            <p>Business Empowered</p>
          </div>
        </div>
      </div>

      <!-- services -->
      <div class="services">
        <h2>What we Offer</h2>
        <services-card
          :service="service"
          v-for="(service, index) in servicesPost"
          :key="index"
          :isFirst="index === 0"
          :index="index"
        />
      </div>

      <!-- our partners -->
      <div class="our-partners">
        <h2>Our Partners</h2>

        <div class="h-full">
          <our-partners></our-partners>
        </div>
      </div>
    </div>

    <div class="testimony-section">
      <our-testimonies />
    </div>

  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const ServicesCard = defineAsyncComponent(() =>
  import("@/components/ServicesCard.vue")
);
const OurTestimonies = defineAsyncComponent(() =>
  import("@/components/OurTestimonies.vue")
);
const OurPartners = defineAsyncComponent(() =>
  import("@/components/OurPartners.vue")
);
import NavButton from "@/components/UI/BaseButton.vue";

export default {
  name: "HomeView",
  components: {
    NavButton,
    ServicesCard,
    OurTestimonies,
    OurPartners,
  },

  data() {
    return {
      servicesPost: [
        {
          title: "LOANS",
          catchPhrase: "Easy and Fast Pay day Loans",
          body: "Our loans of up to ₦3,000,000 can assist you fulfill your demands whether you are employed or operate your own business.",
          coverPhoto: "loan-ill",
          link: 'products-loan'
          
        },
        {
          title: "SAVINGS",
          catchPhrase: "Save Smarter, Live Better",
          body: "Maximize your savings with our competitive interest rates. Start growing your wealth today and secure your financial future with us.",
          coverPhoto: "Saving-ill",
          link: 'products-savings'
          
        },
        {
          title: "DEPOSIT",
          catchPhrase: "Increase your income with fixed deposit",
          body: "Lock in your savings with our Fixed Deposit accounts. Enjoy guaranteed returns and watch your money grow steadily over time. Start investing in your future today.",
          coverPhoto: "Vault-ill",
          link: 'products-savings'
          
        },
      ],

      partners: [
        {
          link: "https://nibss-plc.com.ng/",
          image: "Nibbs",
        },
        {
          link: "https://www.remita.net/",
          image: "Remita logo",
        },
        {
          link: "https://crccreditbureau.com/",
          image: "CREDIT bUREAU",
        },
        {
          link: "https://www.creditregistry.ng/",
          image: "Credit registry",
        },
        {
          link: "https://firstcentralcreditbureau.com/?gclid=CjwKCAiA0syqBhBxEiwAeNx9NyJO_HaDh3xnK9SBDUEKLBl3dAwuuFKya9Vwa_n2wchQqAAm94gyjBoC6_cQAvD_BwE",
          image: "First Central",
        },
        {
          link: "https://www.interswitchgroup.com/",
          image: "Interswitch",
        },
      ],
    };
  },

  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
};
</script>

<style lang="scss" scoped>
.home {
  @apply flex flex-col pt-4 my-2;

  @screen md {
    @apply pt-4;
  }
  @screen lg {
    @apply pt-6 my-8;
  }

  .home-wrap {
    @apply px-2 mx-1 mt-4 flex flex-col;

    @screen sm {
      @apply px-4 mx-4;
    }

    @screen md {
      @apply px-14 mx-4;
    }

    @screen xl {
      @apply mt-14 px-20 mx-8;
    }

    @screen 2xl {
      @apply mx-40;
    }

    .welcome-section {
      @apply flex flex-col gap-10;
      border-bottom: 2px solid;
      border-bottom-color: rgba(197, 225, 249, 0.72);
      border-width: 70%;

      @screen lg {
        @apply flex-row;
      }

      .left {
        @apply flex flex-col items-start justify-center text-start mb-10 w-full;

        @screen sm {
          @apply items-start;
        }

        @screen lg {
          @apply w-1/2 mb-20;
        }

        .message {
          @apply flex flex-col gap-2;

          @screen md {
            @apply max-w-[600px] gap-4;
          }

          h1 {
            @apply text-xl font-bold;
            word-wrap: break-word;

            @screen sm {
              @apply text-[45px];
              letter-spacing: 1.32px;
              line-height: 57px;
            }

            @screen md {
              @apply font-extrabold;
              letter-spacing: 1.32px;
              line-height: 67px;
            }

            @screen xl {
              @apply text-[60px];
            }
          }
          
          p {
            @apply text-sm font-normal leading-6 break-words;

            @screen sm {
              @apply text-base leading-8;
            }
            @screen md {
              @apply text-lg;
            }
          }
        }

        .action {
          @apply flex gap-8 justify-center items-center mt-6;
          @screen sm {
            @apply mt-8 gap-12;

            .create-account {
              @apply justify-self-end;
            }
          }

          .lm-txt {
            @apply text-base font-normal;
          }
        }

        .license {
          @apply flex gap-4 mt-8;
          @screen sm {
            @apply gap-16 mt-14;
          }

          .licensed-by {
            @apply flex flex-col  gap-4 w-1/2;
            // @screen sm {
            //   @apply gap-4
            // }
            h3 {
              @apply text-xs;
              letter-spacing: 0.04px;
              color: rgba(108.38, 108.38, 108.38, 0.5);
            }

            img {
              @apply w-[67px] h-6;
              @screen md {
                @apply h-8;
              }
            }

            .cbn {
              @apply flex gap-4 items-center;

              img {
                width: 20px;
              }
              h3 {
                @apply whitespace-nowrap;
              }
            }
          }
        }
      }

      .right {
        @apply mb-20 flex w-full;

        @screen lg {
          @apply w-1/2;
        }
        .images {
          @apply grid grid-cols-1 grid-flow-dense w-full h-full gap-5;

          @screen lg {
            @apply grid-cols-2 grid-rows-2;
          }

          img {
            @apply object-cover rounded-xl w-full transition-transform;
            min-height: 250px;

            &:hover {
              @apply saturate-50 scale-95 ease-in-out;
            }
          }

          .first {
            @apply row-span-2 p-2;
            background: rgba(0, 171, 241, 0.1);
            border-radius: 10px;
            // max-height: 550px;
            width: 100%;

            @screen md {
              height: 100%;
            }

            img {
              height: 550px;
            }
          }
          .second {
            @apply hidden px-2 py-1;
            background: rgba(255, 0, 116.04, 0.1);
            border-radius: 10px;
            height: 85%;
            align-self: flex-end;

            @screen lg {
              @apply block;
            }
          }
          .third {
            @apply hidden;
            background: rgba(69.1, 242.45, 128.04, 0.1);
            border-radius: 10px;
            width: 80%;
            height: 85%;

            @screen lg {
              @apply block;
            }

            img {
              width: 187px;
            }
          }
        }
      }
    }

    .key-figures {
      @apply my-6 flex flex-col gap-10 items-center justify-center;

      @screen sm {
        @apply my-16 gap-14;
      }

      @screen xl {
        @apply my-20;
      }

      h3 {
        @apply text-2xl;
        color: #d9d9d9;
      }

      .figures-wrap {
        @apply px-2 flex flex-col flex-wrap gap-3 w-full justify-center;

        @screen md {
          @apply flex-row px-10 gap-6 flex-grow;
        }

        @screen lg {
          @apply px-32;
        }

        .figures {
          @apply flex flex-row gap-4 items-center justify-center border p-4 rounded-2xl;

          @screen md {
            @apply flex-col gap-2 px-8;
          }

          &:hover {
            @apply  bg-[#66ccf6];
            

            h3{
              @apply text-white;
            }

            p{
              @apply text-[#033549]
            }
          }

          h3 {
            @apply text-lg;
            color: #033549;

            @screen md {
              @apply text-2xl;
            }
          }

          p {
            @apply text-sm;
            color: #00abf1;
            letter-spacing: 0.09px;
          }
        }
      }
    }

    .services {
      @apply my-4 flex flex-col gap-4;


      @screen md {
        @apply gap-10;
      }

      @screen lg{
        @apply my-8;
      }

      h2 {
        @apply text-lg font-semibold;

        @screen lg {
          @apply text-2xl;
        }
      }
    }

    .our-partners {
      @apply my-4 flex flex-col gap-3;

      @screen md {
        @apply my-8;
      }

      @screen lg {
        @apply mt-20 gap-10;
      }

      h2 {
        @apply text-lg font-semibold;

        @screen lg {
          @apply text-2xl;
        }
      }

      .partners-wrap {
        @apply px-10 py-3 flex flex-col flex-wrap justify-center items-center gap-1;
        border-radius: 20px;
        border: 1px rgba(76.5, 75.86, 75.86, 0.1) solid;

        @screen sm {
          @apply gap-4;
        }

        @screen md {
          @apply flex-row;
        }

        img {
          max-width: 185px;
          max-height: 110px;
        }
      }
    }
  }
}
</style>
