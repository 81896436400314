import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const FaqList = () => import ('../components/FaqList.vue');

const routes = [
  //kindly login
  {
    path: '/login',
    name: 'kindly-login',
    component: () => import( /* webpackChunkName: "kindly-login" */ '../components/Admin/KindlyLogin.vue'),
    meta: {
      title: 'Login'
    }
  },
  
  {
    path: '/complete-signIn',
    name: 'complete-signIn',
    component: () => import( /* webpackChunkName: "complete-sign-in" */ '../components/Admin/CompleteSignIn.vue'),
    meta: {
      title: 'Signing In'
    }
  },

  // home
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home'
    }
  },

  // About
  {
    path: '/about',
    name: 'about',
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'About Us'
    }
  },

  // Products
  {
    path: '/products-loan',
    name: 'products-loan',
    component: () => import( /* webpackChunkName: "products" */ '../views/LoanProducts.vue'),
    meta: {
      title: 'Loans'
    }
  },

  {
    path: '/products-savings',
    name: 'products-savings',
    component: () => import( /* webpackChunkName: "products" */ '../views/SavingsProducts.vue'),
    meta: {
      title: 'Savings'
    }
  },

  // FAQ
  {
    path: '/help',
    name: 'help',
    component: () => import( /* webpackChunkName: "faq" */ '../views/Help.vue'),
    meta: {
      title: 'Help'
    },

    props: true, 
    children: [
      {
        path: ':id', name: 'Que', component: FaqList 
      }
    ]
  },

  // create account
  {
    path: '/create-account',
    name: 'create-account',
    component: () => import('../views/CreateAcct.vue'),
    meta: {
      title: 'Create Account'
    }
  },

  // admin-login
  {
    path: '/admin-login',
    name: 'admin-login',
    component: () => import('../views/Admin/Login.vue'),
    meta: {
      title: 'Login'
    }
  },

  // admin-register
  {
    path: '/admin-register',
    name: 'admin-register',
    component: () => import('../views/Admin/Register.vue'),
    meta: {
      title: 'Register'
    }
  },

  // account-list
  {
    path: '/account-list',
    name: 'account-list',
    component: () => import('../views/Admin/AccountList.vue'),
    meta: {
      title: 'Account-list'
    },
    
  },

  // customer-form
  {
    path: '/customer-form/:accountId',
    name: 'customer-form',
    component: () => import('../views/Admin/CustomerForm.vue'),
    meta: {
      title: 'Customer-form'
    }
  },

 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  }
});


router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Rockshield MFB`;
  
  next();
})

export default router
